
import './css/App.css';
import HeroBg from './images/hero-bg.png';
import bottom from './images/bottom-bg.png';


function App() {
  return (
    <div className='content'>
      <div className='head'>
        <img src={HeroBg} alt='A koi fish' className='headImage'></img>
        <div className='heading'>I transform complexity into clarity,<br></br>
          <span className='bread'> and establish a common vision through open communication and teamwork.</span>
        </div>
      </div>
      <div className='message'>
        <p>My long time goal is to create a lasting, engaging and positive work environment. </p>
        <p>Transparent vision creates opportunities for innovation and collaborated work environment lead to a growing business.</p>
        <p>Individual wishes and needs are identified though open dialog, and blockers are analyzed for a meaningful ways forward. </p>
    </div>
  
  <div className='wraper'>  
    <div className='grid-info'>
        <div className='information employment'>
          <p className='sub-intro'>Employment</p>
          <p className='sub-head'>SOGETI</p>
          <p className='info-bread'>Lead designer and product design</p>
          <p className='sub-head'> TEDKOMP</p>
          <p className='info-bread'>Lead product design</p>
          <p className='sub-head'>Freelance consultant</p>
          <p className='info-bread'>Idealization and digital product development</p>
          <p className='sub-head'>Indego – advertising agency</p>
          <p className='info-bread'>Final artist and web designer</p>           
        </div>

        <div className='information volunteer'>
            <p className='sub-intro'>Volunteer</p>
            <p className='info-bread'>Mentor – UX design students</p>
            <p className='info-bread'>Board member –  UX education</p>
            <p className='info-bread'>Certified Ski instructor</p>
        </div>

        <div className='information consultant'>
          <p className='sub-intro'>Assignments</p>
          <p className='sub-head'>Inter IKEA -IMC</p>
            <p className='info-bread'>Performed product discovery, focusing on the needs and preferences of users.  Insight on how to prioritize and organize functionality for project  oversight.</p>
            <p className='sub-head'>H&M Business tech</p>
            <p className='info-bread'>Modernize digital legacy tools for store and product management, focus on quality assurance and stakeholder collaboration.</p>
            <p className='sub-head'>Ingka IKEA – IT AB</p>
            <p className='info-bread'>Product visual and interaction alignment. Established a comprehensive understanding of its functionality, ensuring a clear and efficient user  experience.</p>
            <p className='sub-head'>Sony mobile</p>
            <p className='info-bread'>Explored and guided in innovation of extended phone/contact book/message functionality. Contributed to 3D scanning, mobile emergency functionality, and contact security.</p>
        </div>  

        <div className='information numbers'>
          <div className='sub-intro'>My work in numbers.</div>
            <p>Grew UX organization with 200%</p>
            <p>Increased unique identified users with 128% from 7 milion to 9 milion</p>
            <p>Increased unique visitors per month with 320% and a return rate of 80%</p>
            <p>Introduced advanced phone and contact features to 50milion daily users</p>
          </div>
        </div>

        <img src={bottom} className='seaFloor'></img>
     </div>

    </div>
  );
}

export default App;
